.bar {
  width: 30px;
  height: 4px;
  background-color: white;
  margin: 3px 0;
  transition: 0.3s;
  cursor: pointer;
}

.menu-items {
  position: fixed;
  top: 30;
  right: 10px;
  width: 150px;
  background-color: #2E2F33;
  z-index: 100;
  text-align: center;
  border-radius: 5px;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  opacity: 0; /* Initial opacity set to 0 */
  transition: opacity 0.1 s ease-in-out;
}

.menu-items.open {
  opacity: 1;
}

li {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Add line between list items */
}


li:last-child {
  border-bottom: none;
}
