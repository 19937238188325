:root {
  --background-color: #0A0B0C;
  --gray-color: #2e2f33;
}

body {
  font-family: Helvetica;
  background-color: var(--background-color);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.authenticated-page {
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.left-side, .right-side, .App, .authenticated-page, .app-authenticated, .body, .html, .config-page {
  margin: 0;
  height: 100%;
}

.config-page {
  text-align: "-webkit-center";
}

.config-button {
  background-color: #3de73d;
}

.left-side {
  background-color: var(--gray-color);
  display: flex;
  flex-direction: column;
  align-items:center;
  max-width: 10%;
  border-radius: 5px;
  
}
.right-side {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top-menu {
  margin: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.profile-pic{
  padding: 5px;
  border-radius: 50%;
  height: 60px;
}

.main-page {
  margin: 0;
  width: 100%;
   display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  background-color: var(--gray-color);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: large;
  font-family: Helvetica;
  padding: 3px 10px 3px 10px;
  margin: 2px;
}

button:hover {
  cursor: pointer;
}

a:link, a:visited {
  text-decoration: none;
  color: white;
}

textarea:hover {
  cursor:pointer;
}

textarea {
  border: none;
  border-radius: 5px;
  font-family: Helvetica;
  background-color:var(--gray-color);
  resize: none;
  text-align: center;
  font-weight: bold;
  color: white;
  
}

.textarea-data {
  font-size: x-small;
}

.tenant-selector {
  background-color: var(--gray-color);
  font-size: calc(10px + 1vmin);
  border-radius: 5px;
  border: none;
  color: white;
  
}

select, .account-picker, .tenant-selector {
  max-width: 90%;
  font-size: small;
}
